import Lulu from '../../assets/images/lulu.webp'
import Griselle from '../../assets/images/griselle.webp'
import Giovani from '../../assets/images/giovani.webp'
// import Moses from '../../assets/images/moses.webp'
//import Angel from '../../assets/images/angel.webp'
// import Anthony from '../../assets/images/anthony.webp'
// import LuluInvert from '../../assets/images/lulu-inverted.webp'
// import Danny from '../../assets/images/danny.webp'

export interface Barber {
    name: string,
    bookingUrl: string,
    image: string
}

export const barbers: Barber[] = [
    {
        name: 'Lulu',
        bookingUrl: 'https://squareup.com/appointments/buyer/widget/ys0bxddu3du8zj/2WYPSWE8ZTKKN',
        image: Lulu,
    }, 
    {
        name: 'Griselle',
        bookingUrl: 'https://app.squareup.com/appointments/buyer/widget/drmgrcl0hm2a74/2WYPSWE8ZTKKN',
        image: Griselle,
    }, 
    {
        name: 'Giovani',
        bookingUrl: 'https://app.squareup.com/appointments/buyer/widget/ywu6b9od3w59yi/2WYPSWE8ZTKKN',
        image: Giovani,
    },
    // {
    //     name: 'Moses',
    //     bookingUrl: 'https://square.site/appointments/buyer/widget/yk206anh7l7u1p/9X5BKE90G9AJC',
    //     image: Moses,
    // },
    // {
    //     name: 'Danny',
    //     bookingUrl: 'https://square.site/appointments/buyer/widget/4o2ekucfwai977/L560Q0W22YB8R',
    //     image: Danny,
    // },
    // {
    //     name: 'Anthony',
    //     bookingUrl: 'https://app.squareup.com/appointments/buyer/widget/wsj32b478tbsmc/2WYPSWE8ZTKKN',
    //     image: Anthony,
    // }
    // {
    //     name: 'Angel',
    //     bookingUrl: 'https://squareup.com/appointments/buyer/widget/mpyrxwnfpgyis0/2WYPSWE8ZTKKN',
    //     image: Angel,
    // },

]
